/*
 * Global application theme.
 * Framework overrides and customization goes here.
 */

html,
body {
  height: 100%;
}

body {
  background-image: linear-gradient(
      90deg,
      rgb(108, 178, 188, 0.8) 1.89%,
      rgb(102, 154, 184, 0.8) 43.22%,
      rgb(95, 144, 177, 0.8) 89.09%
    ),
    url("/assets/images/background.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.body-bg {
  position: relative;
  min-height: 100vh;
  display: grid;
  align-items: center;
}

.bg-gradient {
  background-image: linear-gradient(
    90deg,
    #6cb2bc 1.89%,
    #669ab8 43.22%,
    #5f90b1 89.09%
  ) !important;
}

.bg-dark {
  background-color: $dark-purple !important;
}

.bg-maintenance {
  background: $white-3 !important;
}

.overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1002;
  background-color: rgba(255, 255, 255, 0.1);
  width: 100%;
  height: 100%;

  .loader-wrapper {
    .loader {
      position: absolute;
      top: 50%;
      left: 40%;
      margin-left: 10%;
      transform: translate3d(-50%, -50%, 0);
    }

    .dot {
      width: 24px;
      height: 24px;
      background: #3ac;
      border-radius: 100%;
      display: inline-block;
      z-index: 1;
      animation: slide 1s infinite;
    }

    .dot:nth-child(1) {
      animation-delay: 0.1s;
      background: #32aacc;
    }

    .dot:nth-child(2) {
      animation-delay: 0.2s;
      background: #64aacc;
    }

    .dot:nth-child(3) {
      animation-delay: 0.3s;
      background: #96aacc;
    }

    .dot:nth-child(4) {
      animation-delay: 0.4s;
      background: #c8aacc;
    }

    .dot:nth-child(5) {
      animation-delay: 0.5s;
      background: #faaacc;
    }

    @keyframes slide {
      0% {
        opacity: 0.3;
        transform: scale(1);
      }
      50% {
        opacity: 0.3;
        transform: scale(2);
      }
      100% {
        opacity: 0.3;
        transform: scale(1);
      }
    }
  }
}

// Primeng Overlaypanel override styles
.p-overlaypanel.p-component {
  max-width: 300px;
  margin-top: $spacer * 0.2;

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }

  .p-overlaypanel-content {
    padding: $spacer * 0.7;

    p {
      @include font-type(type-13);
    }
  }

  @media (max-width: 480px) {
    left: 40px !important;
  }
}
