.main-container {
  width: 100%;
  max-width: 1300px;
  min-height: 600px;
  margin: $spacer * 5.2 0px;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 21px 35px $blue-3;

  @media (max-width: 1336px) {
    width: 95%;
  }

  @media (max-width: 991px) {
    width: 94%;
    height: auto;
  }
}
