/*
 * Entry point of global application style.
 * Component-specific style should not go here and be included directly as part of the components.
 */

/*
 * 1. Configuration and helpers
 */

@import "abstracts/mixins";
@import "abstracts/functions";
@import "abstracts/utilities";
@import "abstracts/variables";
@import "abstracts/buttons";

/*
 * 2. Theme variables, must be included before
 * the libraries to allow overriding defaults
 */

@import "theme/theme-variables";

/*
 * 3. 3rd party libraries
 */

@import "~bootstrap/scss/bootstrap";
@import "/node_modules/flag-icon-css/sass/flag-icons";
@import "~@hivvetech/icons/font/vv-icons";

/*
* 4. Theme customization
*/

@import "theme/theme";

/*
* 5. Base stuff
*/

@import "base/base";
