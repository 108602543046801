/*
 * Custom Colors
 */

$white: #fff;
$white-1: #f7f8fc;
$white-2: #ddd;
$white-3: #f7f5fa;

$black: #000;
$black-google: #505052;

$gray: #9ca7a9;
$gray-1: #657177;
$gray-2: #dbd6d6;
$gray-3: #b6bfc8;
$gray-4: #e9ecef;
$gray-light: #edeff8;
$gray-light-1: #eff1f5;
$gray-light-2: #aeafc3;
$gray-dark: #868686;
$gray-dark-1: #ababab;
$charcoal: #505052;
$gray-dark-2: #979797;

$blue: #3474df;
$blue-1: #3f9ec7;
$blue-2: #4c8394;
$blue-3: #31697a;
$blue-4: #0d6e98;
$blue-linkedin: #2f77b0;
$blue-linkedin-hover: #0369a0;
$blue-google-hover: #4285f4;

$violet-dark: #242539;
$violet-dark-1: #282940;
$light-green: #8ec73f;
$green-orcid: #99c523;
$green-orcid-hover: #a6ce39;

$green-dark: #047c3c;

$deep-cobalt: #404364;

$red: #ee3434;
$red-2: #d00f0f;
$red-3: #f05e6b;

$orange: #f26c4a;
$orange-2: #f68f1e;

$green-1: #62c6c8;
$green-2: #009eb3;
$green-3: #4cd137;
$dark-green: #19a0b4;
$amethyst-smoke: #928dac;

$ti-logo-orange: #f78f20;

// Avatar-colors
$orange-color: #ed953d;
$orange-background: #ffd08f;

$pink-color: #e35a67;
$pink-background: #f4a5af;

$light-green-color: #8dc63f;
$light-green-background: #d1e6b2;

$blue-color: #4480c2;
$blue-background: #9cbee4;

$purple-color: #9f6daf;
$purple-background: #ceb8d9;

$dark-green-color: #059fb3;
$dark-green-background: #9cc8d4;

$amber: #f78f1e;

/**
 * Typography
 */

/**
 * Font Imports
 */

@font-face {
  font-family: "Circular Std Black";
  src: url("../../assets/fonts/CircularStd/CircularStd-Black.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Black Italic";
  src: url("../../assets/fonts/CircularStd/CircularStd-BlackItalic.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Bold";
  src: url("../../assets/fonts/CircularStd/CircularStd-Bold.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Bold Italic";
  src: url("../../assets/fonts/CircularStd/CircularStd-BoldItalic.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Book";
  src: url("../../assets/fonts/CircularStd/CircularStd-Book.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Book Italic";
  src: url("../../assets/fonts/CircularStd/CircularStd-BookItalic.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Medium";
  src: url("../../assets/fonts/CircularStd/CircularStd-Medium.woff") format("woff2-variations");
}

@font-face {
  font-family: "Circular Std Medium Italic";
  src: url("../../assets/fonts/CircularStd/CircularStd-MediumItalic.woff")
    format("woff2-variations");
}

/**
 * CSS Font family names with websafe fallback
 * usage:
 * font-family: font-family-type(primary)
 * font: 12px/24px font-family-type(primary)
 */
$font-family-type: (
  primary: (
    "Circular Std Medium",
    CircularStd-Medium,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif
  ),
  circular-std-bold: (
    "Circular Std Bold",
    CircularStd-Bold,
    "Circular Std",
    CircularStd,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    sans-serif
  )
);

/**
 * Font Types
 */
// TODO : need to refactor use generic this will be a problem when introducing themes
$font-types: (
  type-1: (
    font: 700 20px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 25px
  ),
  type-2: (
    font: 450 14px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 24px
  ),
  type-3: (
    font: 450 11px "Circular Std Medium",
    letter-spacing: normal
  ),
  type-4: (
    font: 17px "Circular Std Bold",
    letter-spacing: normal,
    line-height: 25px
  ),
  type-5: (
    font: 700 30px "Circular Std Bold",
    letter-spacing: normal,
    line-height: 30px
  ),
  type-6: (
    font: 450 22px "Circular Std Bold",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-7: (
    font: 700 9px "Circular Std Bold",
    letter-spacing: normal
  ),
  type-8: (
    font: 700 13px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-9: (
    font: 700 17px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 25px
  ),
  type-10: (
    font: 400 12px "Circular Std Book",
    letter-spacing: normal,
    line-height: 12px
  ),
  type-11: (
    font: 450 15px "Circular Std Book",
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-12: (
    font: 700 14px "Circular Std Book",
    letter-spacing: normal
  ),
  type-13: (
    font: 450 13px "Circular Std Book",
    letter-spacing: normal,
    line-height: 19px
  ),
  type-14: (
    font: 700 24px "Circular Std Book",
    letter-spacing: normal,
    line-height: 25px
  ),
  type-15: (
    font: 450 14px "Circular Std Book",
    letter-spacing: normal,
    line-height: 24px
  ),
  type-16: (
    font: 700 18px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-17: (
    font: 400 13px "Circular Std Book",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-18: (
    font: 700 18px "Circular Std Book",
    letter-spacing: normal,
    line-height: 23px
  ),
  type-19: (
    font: 450 14px "Circular Std Book",
    letter-spacing: normal,
    line-height: 21px
  ),
  type-20: (
    font: 700 11px "Circular Std Book",
    letter-spacing: normal,
    line-height: 16px
  ),
  type-21: (
    font: 700 50px "Circular Std Bold",
    letter-spacing: normal,
    line-height: 55px
  ),
  type-22: (
    font: 450 18px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-23: (
    font: 700 40px "Circular Std Bold",
    letter-spacing: normal,
    line-height: 45px
  ),
  type-login-1: (
    font: 700 11px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 30px
  ),
  type-login-welcome: (
    font: 700 26px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-sign-in-label: (
    font: 450 15px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-login-textbox: (
    font: 450 13px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 19px
  ),
  type-or-divider: (
    font: 100 13px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 1.4
  ),
  type-remember: (
    font: 450 13px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 21px
  ),
  type-error-message: (
    font: 450 12px "Circular Std Medium",
    letter-spacing: normal,
    line-height: 12px
  ),
  type-password-help-text: (
    font: 700 11px "Circular Std Book",
    letter-spacing: normal,
    line-height: 27px
  ),
  type-footer: (
    font: 700 13px "Circular Std Book",
    letter-spacing: normal
  ),
  type-left-navi: (
    font: 700 13px "Circular Std Book",
    letter-spacing: normal,
    line-height: 20px
  ),
  type-private-footer: (
    font: normal 13px "Circular Std Book",
    letter-spacing: normal
  ),
  type-notifs: (
    font: 450 12px "Circular Std Book",
    letter-spacing: normal,
    line-height: 13px
  ),
  type-profile-name: (
    font: 700 14px "Circular Std Book",
    letter-spacing: normal,
    line-height: 21px
  )
);

//Spacing
$spacer: 0.625rem !default; // 10px

$modal-fade-transform: scale(0.1);
