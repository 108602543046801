button,
a {
  &.btn {
    height: 43px;
    @include font-type(type-login-1);
    border-radius: 5px;
    box-shadow: 6px 7px 10px 2px rgba(0, 0, 0, 0.3);

    &:hover,
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }

    &.basic {
      background: linear-gradient(90deg, rgba(31, 159, 214, 0.9) 0%, rgba(98, 198, 200, 0.9) 100%);
      border: none;
      color: $white;

      &.icon-btn {
        padding-top: $spacer * 0.7;
      }

      .vvicon {
        font-size: 1.2rem;
        margin-right: $spacer * 0.5;
        vertical-align: -0.125em;
      }

      .text {
        margin-top: $spacer * 0.2;
      }

      &:hover {
        background: linear-gradient(
          89.89deg,
          rgba(13, 110, 152, 0.9) 0%,
          rgba(88, 199, 201, 0.9) 117.43%
        );
      }
    }

    &.outline {
      background: $white;
      color: $charcoal;
      border: 1px solid $charcoal;

      .btn-text {
        margin-top: $spacer * 0.2;
      }

      &.google:hover {
        background: $blue-google-hover;
        border: 1px solid $blue-google-hover;
        color: $white;

        .social-icon-google {
          background: $white;
        }
      }

      &.linkedin {
        border: 1px solid $blue-linkedin;
        color: $blue-linkedin;

        &:hover {
          background: $blue-linkedin-hover;
          color: $white;
        }
      }

      &.orcid {
        border: 1px solid $green-orcid;
        color: $green-orcid;

        &:hover {
          background: $green-orcid-hover;
          color: $white;
        }
      }
    }

    &.borderless {
      box-shadow: none;
    }

    &.btn-gradient-secondary {
      background: linear-gradient(90deg, rgba(243, 109, 77, 1) 0%, rgba(248, 153, 86, 1) 100%);

      &:hover {
        background: linear-gradient(90deg, rgb(226, 71, 39) 0%, rgb(238, 152, 85) 100%);
      }
    }
  }
}
