/**
* Return a font face import
* @param {string} $font The font name
* @param {string} $filename The file name
* @param {number} $font-weight The font weight
* @param {string} $font-style The font style
*/
@mixin font-import($font, $filename, $font-weight: 400, $font-style: normal) {
  @font-face {
    font-family: $font;
    font-style: $font-style;
    font-weight: $font-weight;
    font-display: swap;
    src: url("#{$filename}.woff2") format("woff2"), url("#{$filename}.woff") format("woff");
  }
}

/**
* Return a font-type styling with responsive mapping.
* @param {string} $name The font type styling
*/
@mixin font-type($name: default) {
  $map: if(map_has_key($font-types, $name), map_get($font-types, $name), null);

  @if type-of($map) == map and map_has_key($map, default) {
    @each $respond, $style in $map {
      @if $respond == default {
        @include font-style($style);
      } @else {
        @include respond($respond) {
          @include font-style($style);
        }
      }
    }
  } @else if type-of($map) == string {
    @include font-type($map);
  } @else if type-of($map) == map {
    @include font-style($map);
  }
}

/**
* Return a font-type styling.
* @param {map|string} $map The font mapping
*/
@mixin font-style($map: ()) {
  @if type-of($map) == map {
    @each $type, $value in $map {
      @if $type == type {
        @include font-type($value);
      } @else {
        #{$type}: $value;
      }
    }
  } @else if type-of($map) == string {
    @include font-type($map);
  } @else {
    font: $map;
  }
}
