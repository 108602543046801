/*
 * Application global variables.
 */

@import "../abstracts/variables";

/*
 * Bootstrap variables
 *
 * Override Bootstrap variables here to suite your theme.
 * Copy variables you want to customize from node_modules/bootstrap/scss/_variables.scss
 */

/*
 * Color System
 */

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #868e96;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #0073dd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

// TODO : need to refactor
$dark-purple: #242539;
$light-purple: #404364;
$purple-left-divider: #030305;
$gray2: #8689b1;
$gray-footer: #dbd6d6;
$gray-top-divider: #6c6e7e;
$gray-top-search-line: #b2b5d8;
$gray-prod-divider: #f3f3f3;
$gray-left-divider: #282940;
$green-status: #11c042;
$gray-status-border: #31334f;
$blue-nav-highlight: #3f9ec7;

$theme-colors: (
  primary: $blue,
  secondary: $gray-600,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800
);

/*
 * Typography
 */

$font-size-base: 0.8125rem !default;

$h1-font-size: 24px !default;
$h2-font-size: 24px !default;
$h3-font-size: 20px !default;
$h4-font-size: 16px !default;
$h5-font-size: 15px !default;
$h6-font-size: 14px !default;

$font-weight-light: 400 !default;
$font-weight-normal: 500 !default;
$font-weight-bold: 600 !default;

$headings-font-family: font-family-type(primary);
$headings-font-weight: $font-weight-normal;

$text-muted: $gray-dark-1 !default;

/*
 * Spacing
 */

$spacer: 0.625rem !default;

$zindex-spinner: 100000;

/*
 * Use Bootstrap defaults for other variables, imported here so we can access all
 * app variables in one place when used in components.
 */

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
